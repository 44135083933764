import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
;
import(/* webpackMode: "eager" */ "/app/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["CartInitializer"] */ "/app/src/components/features/cart-modal/cart-initializer.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/cart-modal/modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/navbar/current-customer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/navbar/mobile-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/features/navbar/user-icon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/shared/layout/footer-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/src/components/shared/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrentCustomerProvider"] */ "/app/src/state-management/context/current-customer-context.tsx");
