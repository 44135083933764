import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger
} from "@/components/shared/ui/tooltip";
import { CartTransaction } from "@/lib/cart/cart-types";
import { formatCurrency, util } from "@/lib/library";
import { ShopPrice } from "@/lib/shop/shop-types";
import { BadgeDollarSign } from "lucide-react";

interface PriceDisplayProps {
    price: ShopPrice | null;
    cartTrans?: CartTransaction;
    className?: string;
    variant?: "default" | "medium" | "large";
}

const PriceDisplay = ({
    price,
    cartTrans: cartLine,
    className = "",
    variant = "default"
}: PriceDisplayProps) => {
    if (!price) return null;

    const displayPrice = cartLine ? cartLine.extPrice : price.casePrice;
    const fullPriceDisplay = cartLine
        ? price.unitFullPrice * cartLine.numUnits
        : price.caseFullPrice;
    const displayDiscount = cartLine ? cartLine.discount * cartLine.numUnits : price.caseDiscount;
    const unitPrice = cartLine ? cartLine.extPrice / cartLine.numUnits : price.unitPrice;
    const quantity = cartLine ? cartLine.numUnits : 1;

    const hasQuantityDiscount = !util.isNull(cartLine?.quantityPromotionID);
    const hasDiscount = Number(displayDiscount) > 0;

    const styles = {
        default: {
            container: "-space-y-1",
            icon: "h-3 w-3",
            price: "text-base font-semibold",
            fullPrice: "text-xs",
            discount: "text-xs font-medium",
            label: "text-xs"
        },
        medium: {
            container: "-space-y-0.5",
            icon: "h-3.5 w-3.5",
            price: "text-xl font-bold",
            fullPrice: "text-sm",
            discount: "text-sm font-medium",
            label: "text-xs"
        },
        large: {
            container: "-space-y-0.5",
            icon: "h-4 w-4",
            price: "text-3xl font-bold",
            fullPrice: "text-xl",
            discount: "text-sm font-medium",
            label: "text-xs"
        }
    }[variant];

    const PriceContent = () => (
        <div className={`flex flex-col ${styles.container} ${className}`}>
            <div className="flex items-center gap-1">
                {hasQuantityDiscount && (
                    <BadgeDollarSign className={`${styles.icon} text-gold-500 shrink-0`} />
                )}
                <span className={styles.price}>{formatCurrency(displayPrice)} </span>

                {hasDiscount && (
                    <span className={`${styles.fullPrice} text-gray-500 line-through`}>
                        {formatCurrency(fullPriceDisplay)}
                    </span>
                )}
            </div>
            {hasDiscount && (
                <div className={`flex items-center ${cartLine ? "justify-end" : ""}`}>
                    <span className={`${styles.discount} text-green-600`}>
                        Save {formatCurrency(displayDiscount)}
                    </span>
                </div>
            )}
        </div>
    );

    const TooltipBreakdown = () => (
        <div className="space-y-2 p-1">
            <div className="font-medium">Price Breakdown</div>
            <div className="space-y-1 text-sm">
                {hasDiscount ? (
                    <>
                        <div className="space-y-1 border-b border-gray-200 pb-1">
                            <div className="flex justify-between gap-4">
                                <span>Original Price (per case):</span>
                                <span>{formatCurrency(price.caseFullPrice)}</span>
                            </div>
                            <div className="flex justify-between gap-4">
                                <span>Original Price (per unit):</span>
                                <span>{formatCurrency(price.unitFullPrice)}</span>
                            </div>
                            {hasDiscount && (
                                <div className="flex justify-between gap-4 text-green-600">
                                    <span>Discounted Price (per unit):</span>
                                    <span>{formatCurrency(unitPrice)}</span>
                                </div>
                            )}
                        </div>

                        {quantity > 1 && (
                            <div className="flex justify-between gap-4">
                                <span>Units:</span>
                                <span>× {quantity}</span>
                            </div>
                        )}
                        <div className="flex justify-between gap-4">
                            <span>Subtotal:</span>
                            <span>{formatCurrency(fullPriceDisplay)}</span>
                        </div>
                        <div className="flex justify-between gap-4 text-green-600">
                            <span>Discount:</span>
                            <span>- {formatCurrency(displayDiscount)}</span>
                        </div>
                        {hasQuantityDiscount && (
                            <div className="text-xs italic text-gray-500">
                                *Includes quantity discount
                            </div>
                        )}
                    </>
                ) : (
                    <div className="space-y-1">
                        <div className="flex justify-between gap-4">
                            <span>Price per case:</span>
                            <span>{formatCurrency(price.casePrice)}</span>
                        </div>
                        <div className="flex justify-between gap-4">
                            <span>Price per unit:</span>
                            <span>{formatCurrency(price.unitPrice)}</span>
                        </div>
                    </div>
                )}

                {hasDiscount && (
                    <div className="space-y-1 border-t border-gray-200 pt-1">
                        <div className="flex justify-between gap-4 font-medium">
                            <span>Final Price:</span>
                            <span>{formatCurrency(displayPrice)}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

    if (!hasDiscount) {
        return <PriceContent />;
    }

    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <div className="cursor-help">
                        <PriceContent />
                    </div>
                </TooltipTrigger>
                <TooltipContent side="top" align="start" className="w-72">
                    <TooltipBreakdown />
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    );
};

export default PriceDisplay;
