import CaseUnitInput from "@/components/shared/cart-edit/case-unit-input";
import ProductPrice from "@/components/shared/cart-edit/price-display";
import { CartTransaction } from "@/lib/cart/cart-types";
import Image from "next/image";
import Link from "next/link";
import { DeleteItemButton } from "../../shared/cart-edit/delete-item-button";

export default function CartModalTrans({
    cartTrans,
    index,
    closeCart
}: {
    cartTrans: CartTransaction;
    index: number;
    closeCart: () => void;
}) {
    return (
        <div
            key={index}
            className="relative flex h-[200px] w-full flex-row justify-between px-1 py-4"
        >
            <div className="absolute z-40 -ml-1 -mt-2">
                <DeleteItemButton cartTrans={cartTrans} />
            </div>
            <div className="flex flex-row">
                <div className="relative h-16 w-16 overflow-hidden rounded-md border border-neutral-300 bg-neutral-300 dark:border-neutral-700 dark:bg-neutral-900 dark:hover:bg-neutral-800">
                    <Image
                        className="h-full w-full object-cover"
                        width={64}
                        height={64}
                        alt={cartTrans.productName ?? "Product Image"}
                        src={cartTrans.productThumbnailImageURL!}
                    />
                </div>
                <Link
                    href={`/${cartTrans.productState}/product/${cartTrans.productID}`}
                    onClick={closeCart}
                    className="z-30 ml-2 flex flex-row space-x-4"
                >
                    <div className="flex flex-1 flex-col text-base">
                        <span className="leading-tight">
                            {cartTrans.productName +
                                (cartTrans.manualPromotionID
                                    ? ` (${cartTrans.manualPromotionID})`
                                    : "")}
                        </span>
                    </div>
                </Link>
            </div>
            <div className="flex h-16 flex-col justify-between">
                <ProductPrice
                    className="flex justify-end space-y-2 text-right text-sm"
                    price={{
                        unitPrice: cartTrans.unitPrice,
                        casePrice: cartTrans.unitPrice * cartTrans.wholesaleUnitsPerCase,
                        unitFullPrice: cartTrans.unitFullPrice,
                        caseFullPrice: cartTrans.unitFullPrice * cartTrans.wholesaleUnitsPerCase,
                        unitDiscount: cartTrans.discount,
                        caseDiscount: cartTrans.discount * cartTrans.wholesaleUnitsPerCase,
                        availableQuantityDiscounts: null,
                        promotions: null
                    }}
                    cartTrans={cartTrans}
                />
                <CaseUnitInput
                    productID={cartTrans.productID}
                    wholesaleUnitsPerCase={cartTrans.wholesaleUnitsPerCase}
                    sellByCaseOnly={cartTrans.sellByCaseOnly ?? false}
                    promotionID={cartTrans.manualPromotionID}
                    initialQuantity={{
                        cases: Math.floor(cartTrans.numUnits / cartTrans.wholesaleUnitsPerCase),
                        units: cartTrans.numUnits % cartTrans.wholesaleUnitsPerCase
                    }}
                />
            </div>
        </div>
    );
}
